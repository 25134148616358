.landing {
  width: calc(100% - 40.521vw);
  background-size: auto 44.469vw;
  padding-left: 6.354vw;
  padding-top: 4.375vw;
  background-image: url('../../images/illustration1.svg');
  background-position: center top;
  background-repeat: no-repeat;
}

.landing>img.logo {
  width: 10.104vw;
  height: 1.323vw;
}

h1 {
  font-size: 1.823vw;
  line-height: 1.17;
  margin-bottom: 0 !important;
}

.landing h1 {
  margin-top: 3.005vw;
  color: var(--white);
}

.onboarding {
  width: 40.521vw;
  border-radius: 2vw 0 0 2vw;
}

.onboarding>nav {
  display: flex;
  height: 7.214vw;
  border-bottom: 0.052vw solid var(--light-grey);
  align-items: center;
  justify-content: center;
}

.onboarding>nav>ul {
  margin-top: 1em;
}

main>header img {
  width: 10.104vw;
}

.loading-wrapper header,
main>header {
  padding: 4.401vw 0 5.870vw 0;
}

form,
header {
  width: 21.302vw;
}

legend {
  font-size: 1.2vw !important;
  font-family: var(--font-medium) !important;
  border-bottom: 0 !important;
  margin-bottom: 0.5em !important;
  color: inherit !important;
}

select,
form>div>label,
form input {
  margin-bottom: 1.094vw;
}

.package {
  border: solid 0.052vw var(--warm-grey) !important;
  border-radius: 10px 10px 0 0 !important;
}

.package .ant-card-head {
  background: var(--toupe) !important;
  color: var(--white);
  font-weight: bold;
  border-bottom: solid 0.052vw var(--warm-grey);
  border-radius: 10px 10px 0 0;
}

.package .ant-card-head .ant-card-head-title {
  font-size: 16px;
}

.package .ant-card-actions {
  border-top: 1px solid var(--warm-grey) !important;
}

.package .ant-card-actions li {
  width: auto !important;
  float: right;
  padding: 0 20px;
}

.package h2 {
  margin-bottom: 0;
}

.package h2,
.package h4 {
  font-weight: bold;
}

.package .price {
  text-align: right;
}

.package button {
  padding: 0 1.797vw;
  border: solid 0.052vw var(--toupe) !important;
  border-radius: 0.417vw;
}

.package button:hover {
  background-color: var(--toupe);
  color: var(--white);
}

.package hr {
  margin-bottom: 1.276vw;
  border: 0;
  padding: 0;
  border-top: 1px solid var(--light-grey-two);
}

.package .details span.tag {
  border: 0.052vw solid var(--toupe);
  font-size: 8px;
  margin-left: 0.3vw;
  border-radius: 0 5px;
  padding: 2px;
  line-height: 1;
}

.package .details .features, 
.package .details .period-pricing {
  padding: 0 0 0 2vw;
}

.package .period-pricing .price h1,
.package .period-pricing .price h4 {
  color: var(--toupe);
}

.package .period-pricing .price h1 span {
  font-size: 12px;
}

.mobile-frame {
  height: 35.484vw;
  width: 17.990vw;
}

.mobile-frame h1,
.mobile-frame h2 {
  font-size: 1.094vw;
}

.mobile-frame h1 {
  margin-bottom: 1.167vw;
}

.mobile-frame img {
  margin-top: 1.167vw;
  margin-bottom: 3.089vw;
}

.mobile-background {
  width: 16.198vw;
  height: 34.219vw;
  border-radius: 1.771vw;
  z-index: 900;
  filter: brightness(0.5) contrast(1);
}

.file-upload label {
  padding: 0 1.563vw;
}

.file-upload .cropper {
  padding: 1vw 0;
}

.file-upload .cropper>div:first-child {
  margin-right: 1.615vw;
}

.file-upload .preview {
  width: 22.604vw;
  height: 11.719vw;
}

.file-upload .preview img {
  width: 100%;
}

.file-upload .cropper label {
  padding-left: 0;
}

.file-upload .preview .circle {
  border-radius: 50%;
}

.file-upload .preview .round-corner {
  border-radius: 0.417vw;
}

.file-upload .crop {
  width: 11.719vw;
  height: 11.719vw;
}

.file-upload .drag-drop {
  margin-top: 1.510vw;
  width: 25.604vw;
  padding: 0.469vw;
  border-radius: 0.417vw;
}

.file-upload .drag-drop>.wrapper {
  border: dashed 0.052vw var(--greyish-brown-two);
  border-radius: 0.417vw;
}


.drag-drop h4 {
  padding-bottom: 0.417vw;
  padding-top: 0.573vw;
}

.drag-drop h4 span {
  text-transform: lowercase;
}

.drag-drop img {
  margin-top: 0.917vw;
  width: 2.344vw;
  height: 2.776vw;
}

.drag-drop label {
  padding-bottom: 1.667vw;
  font-size: 0.7vw;
}

a.button {
  min-width: 12.344vw;
}

select,
.button,
.file-upload button,
.ant-btn {
  border: solid 0.052vw var(--light-grey) !important;
  border-radius: 8px !important;
}

.file-upload .preview>div {
  width: 7.188vw;
  height: 7.188vw;
}

.button,
.file-upload button,
form input[type="submit"],
.ant-btn-primary {
  box-shadow: 0 0.156vw 0.313vw 0 var(--black-16) !important;
}

.file-upload .file-list>div {
  margin-top: 0.5vw;
}

.file-upload .file-list button {
  padding: 0;
  padding: 0.156vw;
}


.form-footer {
  font-size: 0.781vw;
}

nav ul {
  justify-content: space-evenly;
}

.onboarding.full-width>nav {
  padding: 2.396vw 7.604vw 2.396vw 10.350vw;
}

.onboarding.full-width>nav ul {
  padding-right: 2.396vw;
}

.onboarding.full-width>nav>img {
  width: 14.104vw;
}

nav ul li .circle {
  width: 3.292vw;
  height: 3.292vw;
  padding: 0.5vw;
}

nav ul li .circle:after {
  content: '';
  display: block;
  height: 0.026vw;
  width: 10.214vw;
  left: 2.8vw;
  top: 1.1vw;
  position: relative;
  background-color: var(--light-grey-two);
}

.onboarding.full-width>nav ul li .circle:after {
  width: 8vw;
}

.onboarding.full-width>nav ul li:last-child .circle:after {
  display: none;
}

.full-width main {
  padding: 3.313vw 9.313vw 3.313vw 12.656vw;
  height: calc(100vh - 7.214vw);
}

.full-width form {
  width: 100%;
}

.full-width form>label {
  font-size: 0.833vw;
  padding-bottom: 0.521vw;
}

.full-width form>div>label {
  padding-left: 1.563vw;
}

.button-wrap,
.full-width form .button-wrap {
  padding-top: 8.854vw;
}


.full-width form .button-wrap input {
  max-width: 8.333vw
}

.full-width form>div.profile-form>label {
  padding-left: 0;
  margin: 0;
  padding: 1.563vw 0 1.094vw 0;
}

.clouds {
  height: 10.870vw;
  width: calc(100% + 2vw);
}

.clouds>div:nth-child(1) {
  height: 2.271vw;
  width: 3.833vw;
  left: 4vw;
  top: 1vw;
}

.clouds>div:nth-child(2) {
  height: 1.698vw;
  width: 2.859vw;
  left: -1.3vw;
  top: -3vw;
}

.clouds>div:nth-child(3) {
  height: 2.271vw;
  width: 3.833vw;
  left: 8vw;
}

.clouds>div:nth-child(4) {
  height: 3.839vw;
  width: 6.479vw;
  top: -4vw;
}

.onboarding-end h1 {
  padding-top: 5.938vw;
  font-weight: bold;
}

.onboarding-end p {
  padding: 2.188vw 0 5.990vw 0;
}

.onboarding-end img {
  width: 13.255vw;
}

.artwork img {
  width: 18.953vw;
  height: 27.380vw;
}

.onboarding-end legend {
  padding-bottom: 4.896vw;
}

.file-progress {
  max-width: 30vw;
}

.menus-form .file-upload {
  padding: 1vw 0 0 0;
}
@primary-color: #b19b63;