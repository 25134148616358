@import '~antd/dist/antd.less';
@import 'fontfaces.css';
@import 'variables.css';

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@import 'responsive/global.css';
@import 'responsive/desktop.css';
@import 'responsive/tablet.css';
@import 'responsive/mobile.css';
@primary-color: #b19b63;