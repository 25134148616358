@font-face {
  font-family: 'SF UI Display Ultralight';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Ultralight'), url('../fonts/sf-ui-display-ultralight.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Thin';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Thin'), url('../fonts/sf-ui-display-thin.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Light';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Light'), url('../fonts/sf-ui-display-light.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Medium'), url('../fonts/sf-ui-display-medium.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Semibold'), url('../fonts/sf-ui-display-semibold.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Bold'), url('../fonts/sf-ui-display-bold.woff') format('woff');
}
@primary-color: #b19b63;