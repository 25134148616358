:root {
  /** FONTS **/
  --font-ultralight: 'SF UI Display Ultralight'; 
  --font-thin: 'SF UI Display Thin';
  --font-light: 'SF UI Display Light';
  --font-medium: 'SF UI Display Medium';
  --font-semibold: 'SF UI Display Semibold';
  --font-bold: 'SF UI Display Bold';

  /** COLORS **/
  --dark-beige: #b19b63;
  --dark-khaki: #9c8752;
  --very-light-pink: #fffefc;
  --light-grey: #cbccc9;
  --light-grey-two: #cdcecb;
  --pale-peach: #ffe5a3;
  --warm-grey: #707070;
  --black: #232323;
  --black-16: rgba(0, 0, 0, 0.16);
  --white: #ffffff;
  --light-tan: #fce5a9;
  --sandstone: #c8b279;
  --warm-grey-two: #898989;
  --greyish-brown: #3d3d3d;
  --light-grey-three: #f4f0e9;
  --greyish-brown-two: #3e3e3e;
  --pinkish-grey: #bfbfbf;
  --toupe: #c4ae75;
  --brown: brown;
  --white-two: #f8f8f8;

  --light-grey-two-t: #cdcecb5b;
}

@primary-color: #b19b63;