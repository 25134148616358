@media (max-width: 40rem) {
  body {
    background-image: none;
    background-color: var(--black) !important;
  }

  #app-root {
    flex-direction: column;
  }

  .clouds {
    display: none;
  }

  .landing,
  .onboarding {
    width: 100%;
    min-height: calc(100vh - 17.333vw);
  }

  .onboarding {
    background-color: var(--very-light-pink);
  }

  .landing .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    padding: 4.267vw 0;
  }

  .landing .logo img {
    width: 20.720vw;
  }

  .landing {
    padding: 0;
    background: none;
    text-align: center;
  }

  .landing button {
    margin-top: 16vw;
    padding: 2.667vw 6.667vw;
    font-size: 3.200vw;
    border: none;
    font-family: var(--font-medium);
    border-radius: 2.133vw;
    box-shadow: 0 0.8vw 1.600vw 0 var(--black-16);
    color: var(--white);
    background-image: linear-gradient(31deg, var(--dark-beige) -1%, var(--light-tan) 281%);
  }

  .landing .content {
    background: url('../../images/illustration1-m.svg') center no-repeat;
    background-size: 79.920vw auto;
    height: 117.867vw;
  }

  .landing h1 {
    padding-top: 21.333vw;
    margin: 0;
    text-align: center;
    font-size: 6.667vw;
  }

  .full-width main {
    padding: 11.200vw;
    flex-direction: column;
  }

  .onboarding.full-width {
    background-image: none;
  }

  .onboarding.full-width>nav {
    background-color: var(--white-two);
    font-size: 2.133vw;
    height: 13.333vw;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .onboarding.full-width>nav ul li .circle:after {
    width: 25vw;
  }
  
  /* .onboarding.full-width>nav ul li:last-child .circle:after {
    display: block;
  } */

  legend {
    font-size: 3.733vw !important;
  }

  .form-footer {
    font-size: 2.933vw;
  }

  .button,
  .file-upload button,
  form input {
    font-size: 2.933vw;
    border-radius: 2.133vw;
    padding: 2.933vw;
  }
  
  legend, form > div > label {
    margin-bottom: 5.067vw !important;
  }

  nav {
    overflow:hidden;
  }

  nav ul {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
  }

  nav ul li .circle {
    width: 8.067vw;
    height: 8.067vw;
  }
  
  nav ul li .circle:after {
    content: '';
    display: block;
    height: 0.133vw;
    width: 28vw;
    position: relative;
    left: 7.8vw;
    top: 4vw;
    background-color: var(--light-grey-two);
  }

  nav ul.steps li:not(.active),
  nav ul.steps li.active.last .circle:after {
    display: none;
  }

  nav ul.steps li.active + li,
  nav ul.steps li.complete.prev {
    display: flex;
  }

  header.full-width {
    font-family: var(--font-medium);
    padding: 3.733vw 0;
    background: var(--white);
  }

  header.full-width h3 {
    font-size: 4vw;
  }

  header.full-width img {
    width: 29.253vw;
  }

  /* .full-width form input {
    width: auto;
  } */

  p.error {
    font-size: 2.400vw;
  }
}
@primary-color: #b19b63;