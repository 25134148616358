
* {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: var(--font-light);
}

h1>span {
  font-family: var(--font-bold);
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  background-image: linear-gradient(14deg, #000000 -20%, #232323 47%, #242424 75%, #545454 157%);
  background-repeat: no-repeat;
  background-size: cover;
}

nav {
  font-family: var(--font-medium);
  overflow: hidden;
}

nav ul {
  display: flex;
  width: 100%;
  list-style: none;
}

nav ul li {
  width: 100%;
}

nav ul a,
nav ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--light-grey);
  text-decoration: none;
}

nav ul li.active a,
nav ul li.active {
  color: var(--warm-grey);
}

nav ul li .circle {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../../images/step.svg');
}

nav ul li.active .circle {
  background-image: url('../../images/step-active.svg');
}

nav ul li.complete .circle {
  background-image: url('../../images/step-pass.svg');
}


#app-root {
  display: flex;
  min-height: 100%;
}

.landing {
  color: var(--white);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.row {
  flex-direction: row;
}

.flex.stretch {
  justify-content: stretch;
}

.flex.flex-start {
  align-items: flex-start;
}

.flex.align-center {
  align-items: center;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.space-even {
  justify-content: space-evenly;
}

.flex.flex-end {
  align-items: flex-end;
}

.flex.flex-end input {
  margin-bottom: 0;
}

.flex.justify-center {
  justify-content: center;
}

p.error {
  color: brown;
}

input[type="file"] {
  display: none;
}
.full-width {
  width: 100%;
}

.onboarding {
  background-color: var(--very-light-pink);
  border-radius: 0;
}

.onboarding.full-width {
  width: 100%;
  border-radius: 0;
  background-image: url('../../images/full-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

.form-footer span:hover {
  cursor: pointer;
  text-decoration: none;
}

.clouds>div {
  background-image: url('../../images/cloud.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.ReactCrop__crop-selection {
  border-radius: 50%;
}

h1 {
  letter-spacing: normal;
}

span.error {
  color: var(--brown);
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
}

legend {
  font-family: var(--font-medium) !important;
}

.package {
  font-family: var(--font-light);
}

.package .title {
  background-color: var(--toupe);
  color: var(--white);
}


.package .price {
  color: var(--toupe);
}

.package button {
  color: var(--toupe);
  background: var(--white);
  cursor: pointer;
}

h4 > span,
.package ul li>span {
  color: var(--toupe);
  display: inline-block;
  text-transform: uppercase;
}


.package ul {
  list-style: none;
}

.mobile-frame {
  background: url('../../images/mob-frame.svg') no-repeat center center;
  background-size: contain;
  position: relative;
  z-index: 901;
  color: var(--white);
}

.mobile-frame h1, .mobile-frame h2 {
  font-family: var(--font-light);
  color: var(--white);
}

.mobile-frame h1 {
  text-transform: uppercase;
  text-align: center;
  width: 15vw;
}

.mobile-background {
  position: absolute;
  background: var(--warm-grey-two);
  z-index: 900;
  background-position: center;
  background-size: cover;
  filter: brightness(0.5) contrast(1);
}

.loading-wrapper {
  width: 100%;
}
.loading-wrapper h3 {
  color: var(--white);
  font-family: var(--font-medium);
}

main p {
  font-family: var(--font-light);
}

.file-upload label {
  color: var(--dark-beige);
  font-family: var(--font-light);
}

.file-upload .preview {
  background-color: var(--light-grey-two-t);
}

.file-upload .preview > div {
  overflow: hidden;
  background-color: var(--white);
}

.file-upload .preview img {
  width: 100%;
}

.file-upload .cropper label {
  padding-left: 0;
}

.file-upload .preview .circle {
  border-radius: 50%;
}

.file-upload .crop {
  background-color: var(--greyish-brown);
}

.file-upload .drag-drop {
  background-image: linear-gradient(to bottom, var(--light-grey-three), var(--white));
}

.file-upload .file-drop-dragging-over-target > .drag-drop > .wrapper {
  border-color: var(--dark-khaki);
}

.drag-drop h4 {
  font-family: var(--font-light);
  color: var(--warm-grey);
}

.drag-drop h4 span {
  color: var(--dark-beige);
}

.drag-drop h4 span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.drag-drop label {
  font-family: var(--font-medium);
  color: var(--pinkish-grey);
}

a.button {
  text-decoration: none;
  font-family: var(--font-light);
  text-align: center;
}

.button,
.file-upload button,
form input[type="submit"],
.ant-btn-primary {
  color: var(--white);
  background-image: linear-gradient(41deg, var(--dark-beige) 21%, var(--light-tan) 214%) !important;
  cursor: pointer;
}

.file-upload .file-list > div {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.file-upload .file-list > div > button {
  background: var(--brown) !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[disabled] {
  background-image: none !important;
  background-color: var(--warm-grey-two) !important;
}

.form-footer {
  font-family: var(--font-light);
  text-align: left;
  margin-top: 10px;
}

.form-footer a,
.form-footer span {
  color: var(--sandstone);
  text-decoration: underline;
}

.onboarding.full-width>nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none;
}

.full-width main {
  flex-direction: row;
  align-items: flex-start;
}

.full-width form {
  width: 100%;
}

.full-width form > label {
  font-family: var(--font-light);
}

/* .full-width form>div {
  display: flex;
  align-items: center;
} */

.full-width form>div>label {
  font-family: var(--font-light);
  color: var(--dark-beige);
}

.button-wrap,
.full-width form .button-wrap { 
  display: flex;
  justify-content: flex-end;
}

.profile-form p:not(.error) {
  color: var(--warm-grey-two);
}

.full-width form>div.profile-form>label {
  padding-left: 0;
  margin: 0;
  color: var(--warm-grey);
}

.clouds {
  display: flex;
  position: relative;
  z-index: 999;
  align-items: center;
  justify-content: space-between;
}

.clouds>div {
  position: relative;
}

input[type="submit"] {
  border: none;
}

.ant-form .ant-input,
.ant-form .ant-input-password,
.ant-form .ant-select.ant-select-single .ant-select-selector {
  border-radius: 8px;
}

.ant-form .ant-form-item {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}
@primary-color: #b19b63;